import React from "react"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import Footer from "../components/Footer"
import BlogDetails from "../components/BlogDetails"
import Nav from "../components/Nav"
import { graphql } from "gatsby"
import ProjectDetails from "../components/ProjectDetails"

export const query = graphql`
    query($id: String!) {
        allStrapiProjects(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                description
                shortdescription
                img {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`

const ProjectDetailPage = ({ data }) => {
  const projectContent = data.allStrapiProjects.nodes[0]

  return (
    <Layout
      title={"Rolteca | " + projectContent.title}
      description={projectContent.description}
      slug={projectContent.slug}
      image={projectContent.image}>
      <Nav />
      <PageHeader title={projectContent.title} />
      <ProjectDetails
        projectContent={projectContent}/>
      <Footer />
    </Layout>
  )
}

export default ProjectDetailPage
