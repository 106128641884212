import React from "react";
import Img from "gatsby-image"
import { Link } from "gatsby"

const ProjectDetails = ({ projectContent }) => {
  console.log(projectContent);
  return (
    <section className="blog-details">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-one__single">
              <div className="blog-one__image">
                <Img fluid={projectContent.img.sharp.fluid} alt={projectContent.title} />
              </div>
              <div className="blog-one__content text-center">
                  <p className="blog-one__text text-justify">
                    <div dangerouslySetInnerHTML={{__html: projectContent.description}} />
                  </p>
              </div>
            </div>
            <div className="share-block">
              <div className="social-block">
                <a target="_blank" rel="noreferrer" href={"https://twitter.com/intent/tweet?text=https://rolteca.com/" + projectContent.slug}>
                  <i className="fab fa-twitter"></i>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://www.facebook.com/dialog/share?app_id=1035471519867457&display=popup&href=https%3A%2F%2Frolteca.com%2F%2F"+projectContent.slug}>
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a target="_blank" rel="noreferrer" href={"https://telegram.me/share/url?url=https://rolteca.com" + projectContent.slug}>
                  <i className="fab fa-telegram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar">
              <div className="sidebar__single sidebar__tags">
                <h2 className="sidebar__title">Espacio patrocinado</h2>
                <p>¿Quieres aparecer aquí? contacta con nosotros en <a href="mailto:contacto@rolteca.com"> nuestro correo </a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;
